/*
#***********************************************
#
#      Filename: src/api/exceptionOrder/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 异常订单相关api
#        Create: 2021-11-16 23:23:36
# Last Modified: 2021-11-26 13:59:59
#***********************************************
*/
import request from '@/utils/request'

// 导出数据
export function exportRecord(data) {
  return request({
    url: '/order/exceptionOrder/export',
    method: 'get',
    params: data
  })
}

// 列表
export function list(data) {
  return request({
    url: '/order/exceptionOrder/list/data',
    method: 'get',
    params: data
  })
}

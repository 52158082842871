<!--
#***********************************************
#
#      Filename: src/views/Record/AbnormalRecord.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 异常出场订单
#        Create: 2021-11-11 14:31:01
# Last Modified: 2021-11-29 18:12:10
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @refresh="handleRefresh"
      @export="handleExport" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.fromTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          <el-popover
            v-if="scope.row.cloudEnterPicture"
            placement="right"
            width="400"
            trigger="click"
            style="cursor: pointer">
            <i slot="reference" class="el-icon-picture-outline-round">点击显示</i>
            <el-image
              :src="scope.row.cloudEnterPicture + '?x-oss-process=image/resize,m_fill,w_900,h_500'"
              :preview-src-list="[scope.row.cloudEnterPicture]" />
          </el-popover>
          <i v-else class="el-icon-warning-outline">暂无图片</i>
        </template>
      </el-table-column>
      >
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.toTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop">
        <template slot-scope="scope">
          <el-popover v-if="scope.row.cloudExitPicture" placement="right" width="400" trigger="click">
            <i slot="reference" class="el-icon-picture-outline-round">点击显示</i>
            <el-image
              :src="scope.row.cloudExitPicture + '?x-oss-process=image/resize,m_fill,w_900,h_500'"
              :preview-src-list="[scope.row.cloudExitPicture]" />
          </el-popover>
          <i v-else class="el-icon-warning-outline">暂无图片</i>
        </template>
      </el-table-column>
      >
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop" />
      <el-table-column
        v-if="tableHeadFields[9].isShow"
        :label="tableHeadFields[9].label"
        :width="tableHeadFields[9].width"
        :align="tableHeadFields[9].align"
        :prop="tableHeadFields[9].prop" />
      <el-table-column
        v-if="tableHeadFields[10].isShow"
        :label="tableHeadFields[10].label"
        :width="tableHeadFields[10].width"
        :align="tableHeadFields[10].align"
        :prop="tableHeadFields[10].prop" />
      <el-table-column
        v-if="tableHeadFields[11].isShow"
        :label="tableHeadFields[11].label"
        :width="tableHeadFields[11].width"
        :align="tableHeadFields[11].align"
        :prop="tableHeadFields[11].prop" />
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list, exportRecord } from '@/api/exceptionOrder' // eslint-disable-line
  import moment from 'moment'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'AbnormalRecord',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '车牌号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateTimeRange',
                name: 'dateRange'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '车牌号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carNumber'
          },
          {
            label: '订单编号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'orderId'
          },
          {
            label: '进场时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'fromTime'
          },
          {
            label: '进场图片',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'cloudEnterPicture'
          },
          {
            label: '出场时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'toTime'
          },
          {
            label: '出场图片',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'cloudExitPicture'
          },
          {
            label: '停车时长',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'totalTime'
          },
          {
            label: '应收费用（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'totalPrice'
          },
          {
            label: '线上支付（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'pendingPrice'
          },
          {
            label: '现金支付（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'payPrice'
          },
          {
            label: '异常开闸',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'remark'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'userName'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          export: 'abnormal-record:export'
        },
        startTime: '',
        endTime: ''
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.pId = this.pid
        this.filterData.searchStr && (queryData.carNumber = this.filterData.searchStr)
        if (this.filterData.dateRange) {
          queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        } else {
          queryData.startTime = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
          queryData.endTime = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
        }

        list(queryData)
          .then((res) => {
            if (res.result) {
              this.dataList = res.data.records
              this.total = res.data.total
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 导出数据
       */
      handleExport() {
        this.$confirm('是否导出异常订单列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const _this = this
            const queryData = {}
            queryData.current = 1
            queryData.size = 9999
            queryData.pId = this.pid
            this.filterData.searchStr && (queryData.carNumber = this.filterData.searchStr)
            if (this.filterData.dateRange) {
              queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
              queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
            }
            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.records.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'fromTime') {
                        return _this.parseTime(v[j])
                      } else if (j === 'toTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '异常订单记录')
                })
                this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                this.noticeError(err.message)
              })
          })
          .catch((err) => {
            this.noticeError(err.message)
          })
      },

      /**
       * 过滤数据
       */
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) => {
          return filterVal.map((j) => {
            return v[j]
          })
        })
      }
    }
  }
</script>
